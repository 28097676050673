import React, { useRef, useEffect, useContext } from 'react';
import { AppContext } from '../../context';
import { useHistory } from 'react-router-dom';
import Question from '@bit/bluekeel.component-library.question';
import { home_loan_buttons } from './button_info';

const HomeLoanTypes = () => {
    const { dispatchApp } = useContext(AppContext);
    let history = useHistory();
    const componentIsMounted = useRef(true);

    useEffect(() => {
        if (componentIsMounted.current) {
            dispatchApp({
                type: 'VERTICAL_PICKED',
                payload: { value: 'home_loans', crumb: 'Home Loans' }
            });
        };
        return () => { componentIsMounted.current = false };
        // eslint-disable-next-line
    }, []);

    const handleButtonClick = (e, choice, text) => {
        e.preventDefault();
        dispatchApp({ type: 'LOAN_TYPE_PICKED', payload: { value: choice, crumb: text } });
        window.scrollTo(0, 0);
        history.push('/email_optin');
    };

    return (
        <Question 
            page={{
                buttonData: home_loan_buttons,
                handleClick: handleButtonClick,
                text: 'Select Home Loan Type:'
            }}
            wrapper={{
                theme: 'pcm',
                crumbs: { verticalCrumb: 'Home Loans' },
                flow: { vertical: 'home_loans' },
                isEnd: false
            }}
        />
    )
};

export default HomeLoanTypes;