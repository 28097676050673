export const buildQueryObj = (flow, pid) => ({
    pid,
    vertical: flow.vertical,
    loan_type: flow.loan_type,
    debt_type: flow.debt_type,
    debt_amount: flow.debt_amount
});

// Cookie Tools
export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const checkCookie = (cname) => {
    let cookie = getCookie(cname);
    if (cookie && cookie !== '') {
        return true
    }
    return false;
};

export const setCookies = (tracking) => {
    setCookie('oid', tracking.oid, 3);
    setCookie('pid', tracking.pid, 3);
    setCookie('sid', tracking.sid, 3);
    setCookie('uid', tracking.uid, 3);
    setCookie('eid', tracking.eid, 3);
    setCookie('hsid', tracking.hsid, 3);
    tracking.se && (setCookie('se', tracking.se, 3));
    tracking.kwd && (setCookie('kwd', tracking.kwd, 3));
    tracking.pacid && (setCookie('pacid', tracking.pacid, 3));
    tracking.pt1 && (setCookie('pt1', tracking.pt1, 3));
    tracking.pt2 && (setCookie('pt2', tracking.pt2, 3));
    tracking.gclid && (setCookie('gclid', tracking.gclid, 3));
    tracking.fname && (setCookie('fname', tracking.fname, 3));
    tracking.lname && (setCookie('lname', tracking.lname, 3));
    tracking.address && (setCookie('address', tracking.address, 3));
    tracking.city && (setCookie('city', tracking.city, 3));
    tracking.state && (setCookie('state', tracking.state, 3));
    tracking.zip && (setCookie('zip', tracking.zip, 3));
};

// Local Storage Tools
export const getCachedObject = (key) => {
    const item = localStorage.getItem(key);
    return JSON.parse(item)
};

export const isObjectCached = (key) => {
    return !!localStorage.getItem(key);
};

export const setCachedObject = (key, obj) => {
    localStorage.setItem(key, JSON.stringify(obj));
};

export const flattenString = (string) => {
    let flatString = string.replace(' ', '_');
    return flatString.toLowerCase();
};

export const flattenLongString = (string) => {
    const stringCleaned = string.replace(/\s/g, '_');
    return stringCleaned.toLowerCase();
};

export const capitalizeValue = (value) => {
    let text = value;
    text = text.toLowerCase()
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return text;
};

export const scrollToTop = () => {
    window.scrollTo(0,0);
};

export const pickButtonColor = (idx) => {
    switch (idx) {
        case 0:
            return 'blue';
        case 1:
            return 'green';
        case 2:
            return 'orange';
        case 3:
            return 'purple';
        case 4:
            return 'blue';
        case 5:
            return 'lime';
        case 6:
            return 'yellow';
        case 7:
            return 'green';
        case 8:
            return 'purple';
        case 9:
            return 'orange';
        case 10:
            return 'lime';
        default:
            return 'yellow'
    }
}