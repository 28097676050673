import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Welcome from './components/Pages/Welcome';
import PrivacyPolicy from '@bit/bluekeel.component-library.privacy-policy';
import TermConditions from '@bit/bluekeel.component-library.term-conditions';
import PcmFullLogo from '@bit/bluekeel.assets.pcm_full_logo';
import Verticals from './components/Pages/Verticals';
import AutoLoanTypes from './components/Pages/AutoLoanTypes';
import CreditCardTypes from './components/Pages/CreditCardTypes';
import PersonalLoanTypes from './components/Pages/PersonalLoanTypes';
import HomeLoanTypes from './components/Pages/HomeLoanTypes';
import DebtTypes from './components/Pages/DebtTypes';
import DebtAmount from './components/Pages/DebtAmount';
import DebtLoan from './components/Pages/DebtLoan';
import FreeChecking from './components/Pages/FreeChecking';
import EndUserFlow from './components/Pages/EndUserFlow';

const Policy = () => (<PrivacyPolicy domain='PerfectCreditMatch.com' logo={PcmFullLogo}/>);
const Terms = () => (<TermConditions domain='PerfectCreditMatch.com' logo={PcmFullLogo}/>);

const Routes = () => {
	return (
		<Switch>
			<Route path='/' exact component={Welcome} />
			<Route path='/privacy' component={Policy} />
			<Route path='/terms' component={Terms} />
			<Route path='/verticals' component={Verticals} />
			<Route path='/credit_cards' component={CreditCardTypes} />
			<Route path='/auto_loans' component={AutoLoanTypes} />
			<Route path='/home_loans' component={HomeLoanTypes} />
			<Route path='/personal_loans' component={PersonalLoanTypes} />
			<Route path='/debt_types' component={DebtTypes} />
			<Route path='/debt_amount' component={DebtAmount} />
			<Route path='/debt_optin' component={DebtLoan} />
			<Route path='/checking' component={FreeChecking} />
			<Route path='/email_optin' component={EndUserFlow} />
		</Switch>
	);
};

export default Routes;