export default {
    app: {
        fontFamily: 'Roboto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        backgroundColor: '#ffffff',
    }
};