export const auto_loan_buttons = [{
        icon: ['left', 'fal', 'cars'],
        text: 'Car',
        color: 'orange',
        value: 'car',
        crumb: ''
    },
    {
        icon: ['left', 'fal', 'truck-monster'],
        text: 'SUV',
        color: 'green',
        value: 'suv',
        crumb: ''
    },
    {
        icon: ['left', 'fal', 'truck-pickup'],
        text: 'Truck',
        color: 'purple',
        value: 'truck',
        crumb: ''
    },
    {
        icon: ['left', 'fal', 'shuttle-van'],
        text: 'Van/Minivan',
        color: 'yellow',
        value: 'van',
        crumb: ''
    },
    {
        icon: ['left', 'fal', 'balance-scale-right'],
        text: 'Not Sure',
        color: 'lime',
        value: 'not_sure',
        crumb: ''
    },
];

export const credit_card_buttons = [{
        icon: ['left', 'fal', 'percent'],
        text: 'Low Interest',
        color: 'green',
        value: 'low_interest'
    },
    {
        icon: ['left', 'fal', 'money-bill-wave'],
        text: 'Cash Back',
        color: 'purple',
        value: 'cash_back'
    },
    {
        icon: ['left', 'fal', 'gift'],
        text: 'Reward',
        color: 'orange',
        value: 'rewards'
    },
    {
        icon: ['left', 'fal', 'user-graduate'],
        text: 'Student',
        color: 'blueish',
        value: 'student'
    },
    {
        icon: ['left', 'fal', 'plane-departure'],
        text: 'Travel',
        color: 'lime',
        value: 'travel'
    },
    {
        icon: ['left', 'fal', 'chart-line'],
        text: 'Credit Building',
        color: 'yellow',
        value: 'credit_building'
    },
    {
        icon: ['left', 'fal', 'briefcase'],
        text: 'Business',
        color: 'blueish',
        value: 'business'
    },
    {
        icon: ['left', 'fal', 'exchange'],
        text: 'Balance Transfer',
        color: 'green',
        value: 'balance_transfer'
    },
    {
        icon: ['left', 'fal', 'dollar-sign'],
        text: 'Prepaid',
        color: 'purple',
        value: 'prepaid'
    },
];

export const personal_loan_buttons = [{
        icon: ['left', 'fal', 'lightbulb-dollar'],
        text: 'Debt Consolidation',
        color: 'purple',
        value: 'debt_consolidation',
        crumb: 'Consolidating Debt'
    },
    {
        icon: ['left', 'fal', 'money-check-edit-alt'],
        text: 'Paying Bills',
        color: 'orange',
        value: 'pay_bills',
        crumb: 'Paying Bills'
    },
    {
        icon: ['left', 'fal', 'shopping-cart'],
        text: 'Making a Purchase',
        color: 'lime',
        value: 'make_purchase',
        crumb: 'Making a Purchase'
    },
    {
        icon: ['left', 'fal', 'hands-usd'],
        text: 'Emergency Cash',
        color: 'yellow',
        value: 'emergency_cash',
        crumb: 'Emergency Cash'
    },
    {
        icon: ['left', 'fal', 'landmark-alt'],
        text: 'Paying Taxes',
        color: 'blueish',
        value: 'pay_taxes',
        crumb: 'Paying Taxes'
    },
    {
        icon: ['left', 'fal', 'search-dollar'],
        text: 'Other Reasons',
        color: 'green',
        value: 'other_purpose',
        crumb: 'Other Reasons'
    },
];

export const home_loan_buttons = [{
        icon: ['left', 'fal', 'sort-size-down'],
        text: 'Refinance',
        color: 'blueish',
        value: 'refinance',
        crumb: 'Refinance'
    },
    {
        icon: ['left', 'fal', 'donate'],
        text: 'Lease to Own',
        color: 'green',
        value: 'lease_to_own',
        crumb: 'Lease to Own'
    },
    {
        icon: ['left', 'fal', 'gopuram'],
        text: 'HELOC',
        color: 'purple',
        value: 'heloc',
        crumb: 'HELOC'
    },
    {
        icon: ['left', 'fal', 'shopping-cart'],
        text: 'Purchase',
        color: 'orange',
        value: 'purchase',
        crumb: 'Purchase'
    },
];

export const debt_type_buttons = [{
        icon: ['left', 'fal', 'credit-card'],
        text: 'Credit Cards',
        color: 'purple',
        value: 'credit_card'
    },
    {
        icon: ['left', 'fal', 'hand-holding-usd'],
        text: 'Personal Loans',
        color: 'orange',
        value: 'personal_loan'
    },
    {
        icon: ['left', 'fal', 'heart-rate'],
        text: 'Medical Bills',
        color: 'lime',
        value: 'medical'
    },
    {
        icon: ['left', 'fal', 'user-graduate'],
        text: 'Student Loans',
        color: 'yellow',
        value: 'student_loan'
    },
    {
        icon: ['left', 'fal', 'car'],
        text: 'Auto Loan',
        color: 'blueish',
        value: 'auto_loan'
    },
    {
        icon: ['left', 'fal', 'file-invoice-dollar'],
        text: 'Other Debts',
        color: 'green',
        value: 'other'
    },
];

export const debt_amount_buttons = [{
        icon: ['left', 'fal', 'coin'],
        text: 'Less than $15,000',
        color: 'lime',
        value: '<15000'
    },
    {
        icon: ['left', 'fal', 'coins'],
        text: '$15,000 - $30,000',
        color: 'yellow',
        value: '15000-30000'
    },
    {
        icon: ['left', 'fal', 'money-bill-wave'],
        text: '$30,000 - $50,000',
        color: 'blueish',
        value: '30000-50000'
    },
    {
        icon: ['left', 'fal', 'sack-dollar'],
        text: 'More than $50,000',
        color: 'green',
        value: '>50000'
    },
];

export const vertical_buttons = [{
        icon: ['left', 'fal', 'credit-card'],
        text: 'Credit Cards',
        color: 'blueish',
        value: 'credit_cards'
    },
    {
        icon: ['left', 'fal', 'hand-holding-usd'],
        text: 'Personal Loans',
        color: 'green',
        value: 'personal_loans'
    },
    {
        icon: ['left', 'fal', 'home-heart'],
        text: 'Home Loans',
        color: 'purple',
        value: 'home_loans'
    },
    {
        icon: ['left', 'fal', 'car'],
        text: 'Auto Loans',
        color: 'orange',
        value: 'auto_loans'
    },
];